import { Typography } from "@mui/material";

const Steenbuck = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The Steenbuck is a charming and diminutive antelope species that inhabits
      the savannahs and arid regions of southern and eastern Africa. These
      petite antelopes are known for their delicate appearance and adaptability
      to harsh environments. Steenboks are among the smallest antelopes on the
      continent.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One of the most distinguishing features of steenboks is their absence of
      horns in females and the small, straight horns found in males, are slender
      and pointed, curving slightly backward. These horns are not ornate or
      prominent but serve as a subtle yet functional adaptation for territorial
      disputes and defence.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their coat is usually reddish-brown to fawn, providing excellent
      camouflage in their arid habitats. Their large, expressive eyes and
      distinctive facial markings, including a white ring around each eye, add
      to their charm. Steenboks are known for their remarkable agility and the
      ability to survive in regions with limited water and vegetation. These
      solitary antelopes are primarily browsers, feeding on leaves, shoots, and
      succulent plants. Their small size, delicate features, and ability to
      thrive in harsh conditions make them a symbol of resilience and
      adaptability in Africa's diverse landscapes.
    </Typography>
  </>
);

export default Steenbuck;
