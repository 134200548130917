import logo from '../assets/logo500.png';
import Image from 'react-bootstrap/Image';
import Cover from './Cover';
import HuntGallery from './hunting/HuntGallery';
import Activities from './Activities/Activities';
import ContactUs from './ContactUs/ContactUs';

const Introduction = () => {
  return (
    <>
      <Cover />
      <div className="introduction">
        <Image src={logo} />
        <div className="intro-section">
                <p>Welcome to Olive Creek Safaris! We are delighted to be your ultimate destination for an extraordinary hunting experience, nestled in the stunning Waterberge region of Limpopo, South Africa.</p>
                <p>At Olive Creek Safaris, we cater to passionate hunters who seek the thrill of tracking and encountering the majestic buffalo.</p>
                <p>Situated in the heart of the pristine Waterberge, our dedicated team of professionals shares a profound love for hunting and wildlife conservation. With years of experience, our expert guides will accompany you on your hunting expedition, ensuring your safety and success while adhering to ethical hunting practices and promoting sustainable conservation efforts.</p>
                <p>We offer a range of exceptional accommodations, allowing you to relax and rejuvenate amidst the natural beauty of the surroundings.</p>
                <p>Start planning your ultimate buffalo hunting experience today with us, your trusted partner in the pursuit of African wilderness.</p>
            </div>
      </div>
      <Activities />
      <HuntGallery />
      <ContactUs />
    </>
  );
}

export default Introduction;
