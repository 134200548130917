import { Row, Col } from "react-bootstrap";
import Buffalo2 from "../../assets/images/animals/buffalo2.jpg";
import ImgGiraffe from "../../assets/images/animals/giraffe.jpg";
import ImgHippo from "../../assets/images/animals/hippo.jpg";
import ImgEland from "../../assets/images/animals/eland.jpg";
import MediaCard from "../MediaCard";
import { Animal } from "./AnimalListInfo";
import { Box, Container, Typography } from "@mui/material";
import { useMobile } from "../../hooks/use-mobile.hook";

const AnimalArray = [
  {
    title: Animal.BUFFALO,
    description:
      "Standing at an impressive shoulder height of up to 1.7 meters (5.5 feet) and weighing between 500 to 900 kilograms (1100 to 2000 pounds), the African buffalo is a massive bovine with a distinctive robust build.",
    link: "",
    src: Buffalo2,
  },
  {
    title: Animal.ELAND,
    description:
      "Elands are the largest antelope species in the world. Adult males, known as bulls, can stand around 5 to 6 feet tall at the shoulder and weigh between 900 to 2,200 pounds. Adult females, called cows, are slightly smaller.",
    link: "",
    src: ImgEland,
  },
  {
    title: Animal.HIPPO,
    description:
      "Weighing up to a staggering 2,000 to 3,000 pounds, these behemoths possess an unexpected agility, capable of impressive speed both in water and on land.",
    link: "",
    src: ImgHippo,
  },
  {
    title: Animal.GIRAFFE,
    description:
      "As the tallest land animal on Earth, giraffes command attention, boasting an impressive height of up to 18 feet.",
    link: "",
    src: ImgGiraffe,
  },
];

const HuntGallery = () => {
  const isMobile = useMobile()
  return (
    <Box className="hunt-gallery">
      <Container sx={{ paddingY: 3, maxWidth: "100vw" }}>
        <Typography variant="h4" sx={{ paddingY: 3 }}>
          Hunting
        </Typography>
        <Row>
          {AnimalArray.slice(0, isMobile ? 1 : 4).map((animal, index) => (
            <Col xs={12} lg={3} key={animal.title}>
              <MediaCard
                src={animal.src}
                title={animal.title}
                description={animal.description}
                link="/hunting#info"
              ></MediaCard>
            </Col>
          ))}
        </Row>
      </Container>
    </Box>
  );
};

export default HuntGallery;
