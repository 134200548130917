import { Typography } from "@mui/material";

const Jackal = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Jackals are fascinating and highly adaptable members of the dog family,
      Canidae, found in a variety of habitats across Africa, Asia, and
      southeastern Europe. These medium-sized carnivores exhibit slight
      variations in size and weight depending on the species. The black-backed
      jackal is smaller.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Jackals are opportunistic omnivores, with a diet that includes small
      mammals, birds, insects, fruits, and carrion. Their ability to scavenge
      and hunt makes them important ecological players, as they help control
      rodent populations and contribute to nutrient cycling.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Jackals are known for their keen intelligence, adaptability to changing
      environments, and complex social structures, often living in family groups
      led by a dominant breeding pair. They are renowned for their ability to
      survive in a wide range of ecosystems, from deserts to grasslands,
      showcasing their resilience and evolutionary success.
    </Typography>
  </>
);

export default Jackal;
