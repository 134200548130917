import { Typography } from "@mui/material";

const Bushpig = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Bushpigs are remarkable and sturdy members of the pig family found across
      sub-Saharan Africa's diverse habitats. Renowned for their distinctive
      appearance, these forest-dwelling creatures. Bushpigs are instantly
      recognizable by their shaggy reddish-brown to blackish coats, often
      adorned with noticeable tufts of coarse hair along their backs. Their most
      striking features include elongated snouts and sharp, curved tusks, which
      are more prominent in males and serve as formidable weapons and tools for
      digging.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Bushpigs are versatile omnivores with a varied diet. Their culinary
      preferences encompass a wide range of foods, including roots, fruits,
      tubers, insects, small vertebrates, and even carrion when the opportunity
      arises. These adaptable foragers navigate their environments with
      exceptional agility, often relying on their keen sense of smell to locate
      food sources.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Behaviourally, bushpigs are primarily nocturnal, venturing out under the
      cover of darkness to search for sustenance. Their cautious and elusive
      nature makes them challenging to spot in their natural habitats, which
      range from dense forests and woodlands to grasslands. Despite their
      elusive behaviour, bushpigs play a vital ecological role. They contribute
      to seed dispersal and nutrient recycling, ensuring the health and
      diversity of African ecosystems.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      With their enigmatic presence and the challenges, they pose to wildlife
      enthusiasts and researchers alike, bushpigs continue to intrigue and
      inspire those exploring the rich tapestry of Africa's natural wonders.
    </Typography>
  </>
);

export default Bushpig;
