import { Typography } from "@mui/material";

const Buffalo = () => (
    <>
      <Typography variant="body2" sx={{marginBottom: 1}}>
        The African buffalo, also known as the Cape buffalo, is a majestic and
        formidable creature that roams the grasslands and savannas of sub-Saharan
        Africa. As one of the iconic members of the "Big Five" game animals, this
        magnificent beast is both revered and feared for its strength, resilience,
        and unpredictable nature.
      </Typography>
  
      <Typography variant="body2" sx={{marginBottom: 1}}>
        Standing at an impressive shoulder height of up to 1.7 meters (5.5 feet)
        and weighing between 500 to 900 kilograms (1100 to 2000 pounds), the
        African buffalo is a massive bovine with a distinctive robust build. Both
        males and females bear large, backward-curving horns, which can span up to
        1.5 meters (4.9 feet) across, providing them with a potent defense against
        predators and competitors.
      </Typography>
  
      <Typography variant="body2" sx={{marginBottom: 1}}>
        African buffaloes are highly social animals, forming tight-knit herds that
        can number in the hundreds or even thousands. Within these herds, a
        complex hierarchy is established, with dominant bulls leading and
        protecting the group. Despite their strong social bonds, buffaloes are
        known for their unpredictable behavior and short temper. They can be
        extremely aggressive and will not hesitate to charge if they perceive a
        threat, making them one of the most dangerous animals in Africa.
      </Typography>
  
      <Typography variant="body2" sx={{marginBottom: 1}}>
        Their formidable presence has earned them a nickname: "Black Death." It is
        a fitting moniker, as they have been responsible for more deaths among
        big-game hunters than any other African animal. Their reputation for
        aggression and their tendency to circle back and attack after being
        wounded has put fear into the hearts of many seasoned hunters and
        adventurers.
      </Typography>
  
      <Typography variant="body2" sx={{marginBottom: 1}}>
        The African buffalo's role in the ecosystem is crucial. They are ecosystem
        engineers, shaping and maintaining their environment by grazing on
        vegetation and creating paths through the dense grasslands. This, in turn,
        benefits other herbivores and contributes to the overall health of the
        ecosystem.
      </Typography>
  
      <Typography variant="body2" sx={{marginBottom: 1}}>
        Conservation efforts are essential to protect these magnificent creatures,
        as they face threats from habitat loss, disease, and illegal poaching.
        Their conservation status is listed as "vulnerable," reminding us of the
        urgent need to preserve their natural habitat and ensure their survival
        for generations to come.
      </Typography>
  
      <Typography variant="body2" sx={{marginBottom: 1}}>
        In conclusion, the African buffalo commands respect and admiration for its
        raw power, social structure, and role in shaping the African wilderness.
        It symbolizes the untamed beauty and inherent danger of the African
        savannas, serving as a testament to the remarkable diversity of life that
        graces this vast and awe-inspiring continent.
      </Typography>
    </>
  );
  
  export default Buffalo;