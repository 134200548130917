import Hiking from '../../assets/images/activities/hiking-trail.jpg'
import HorseRiding from '../../assets/images/activities/hr-min.jpg'
import QuadBikes from '../../assets/images/activities/quad-min.jpg'
import GameDrive from '../../assets/images/activities/gd2-2-min.jpg'

export enum Activitie {
    GAMEDRIVES = "Gamedrives",
    HORSERIDING = "Horse riding",
    TRAILWALKS = "Trail walks",
    QUADBIKE = "Quad bike",
    FISHING = "Fishing",
    MOUNTAINBIKETRIALS = "Mountain bike trails",
    BIRDWATCHING = "Bird watching",
}

export const ActivitiesArray = [
    {
        title: Activitie.GAMEDRIVES,
        description:
        "Join us for an exciting wildlife safari in our stunning game reserve. Explore the region's amazing animals with our expert guide.",
        src: GameDrive,
    },
    {
        title: Activitie.HORSERIDING,
        description:
            "Embark on a memorable horse riding adventure through breathtaking landscapes. Discover the beauty of nature from the saddle, guided by our experienced equestrians.",
        src: HorseRiding,
    },
    {
        title: Activitie.TRAILWALKS,
        description:
            "Experience the tranquility of nature on our scenic trail walks. Immerse yourself in the beauty of the outdoors as you explore our picturesque trails guided by our knowledgeable naturalists.",
        src: Hiking,
    },
    {
        title: Activitie.QUADBIKE,
        description:
            "Rev up your adventure with thrilling quad bike rides. Explore rugged terrains and scenic trails on your all-terrain vehicle.",
        src: QuadBikes,
    },
];