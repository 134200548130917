import React, { useState } from 'react';
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

interface GalleryProps {
    images: string[]; // Specify the type as an array of strings
}

function Gallery({ images }: GalleryProps) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % (images?.length ?? 0));
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? (images?.length ?? 0) - 1 : prevIndex - 1
        );
    };

    return (
        <div className="gallery-container">
            <div className="controls">
                <Button className="toggle-button float-end bg-transparent border-0" onClick={prevImage}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
                </Button>
            </div>
            <div className="image-container">
                <Image className="gallery-image"
                    src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} rounded />
            </div>
            <div className="controls">
                <Button className="toggle-button float-end bg-transparent border-0" onClick={nextImage}>
                    <FontAwesomeIcon icon={faChevronRight} style={{ color: 'black' }} />
                </Button>
            </div>
        </div>
    );
}

export default Gallery;
