import { Typography } from "@mui/material";

const RedHartebeest = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The red hartebeest, a striking and resilient antelope species, is a
      testament to the enduring spirit of Africa's open plains and savannahs.
      With its distinctive reddish-brown coat, striking facial markings, and
      unique curved horns, the red hartebeest is instantly recognizable. These
      antelopes exhibit a robust and sturdy build.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their long, lyre-shaped horns extend upward and then curve backward,
      making them appear as if they are always ready to charge across the
      African plains. Males and females both possess these impressive horns,
      although the males' horns are typically thicker and more robust. The
      combination of their colouring, horns, and distinctive profile gives the
      red hartebeest an air of regal magnificence.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Red hartebeests are grazers, primarily feeding on grasses, and they have
      adapted to a nomadic lifestyle, often following the seasonal availability
      of food and water. They are known for their remarkable speed and agility,
      capable of sprinting at impressive speeds when threatened.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      These antelopes are often seen in small groups or as solitary individuals,
      and their presence on the African savannah is not only a testament to
      their ability to thrive in challenging environments but also a symbol of
      the untamed beauty and enduring vitality of Africa's wild landscapes.
    </Typography>
  </>
);

export default RedHartebeest;
