import { Typography } from "@mui/material";

const Baboon = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Baboons, members of the genus Papio, are captivating primates found in
      various habitats across Africa and the Arabian Peninsula. Their striking
      appearance, with dog-like snouts, long tails, and prominent cheek pouches,
      makes them easily recognizable. These highly adaptable creatures thrive in
      diverse environments, from verdant savannahs to arid deserts. Baboons are
      known for their intelligence and complex social structures, living in
      tightly knit troops led by a dominant male, while females play crucial
      roles in maintaining group cohesion.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      What sets baboons apart is their intricate social hierarchy, which is
      enforced through a combination of grooming, vocalizations, and subtle
      gestures. They are also renowned for their omnivorous diet, which includes
      fruits, leaves, insects, small vertebrates, and even the occasional
      scavenged meal.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      A particularly fascinating aspect of baboon behaviour is their grooming
      rituals, which not only serve as a form of social bonding but also help to
      maintain hygiene and remove parasites from their fur. Baboons also have a
      rich vocal repertoire, using various sounds like grunts, barks, and
      screams to communicate with each other.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Despite their impressive adaptability, baboons face challenges due to
      human encroachment, often coming into contact with human settlements and
      farms. This interaction can lead to conflicts, making it essential to find
      ways for humans and baboons to coexist peacefully.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Overall, these complex and intelligent primates continue to captivate
      researchers and wildlife enthusiasts alike, offering valuable insights
      into the intricacies of primate behaviour and the challenges they face in
      an ever-changing world.
    </Typography>
  </>
);

export default Baboon;
