import { Typography } from "@mui/material";

const Ostrich = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The ostrich, Earth's largest and most enigmatic bird, is a creature of
      wonder and paradox. Towering over the African savannah like a sentinel of
      ancient times, the ostrich stands as a living testament to evolution's
      extraordinary experiments. Their sheer size is awe-inspiring.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Yet, beneath their colossal frame lies an intricate tapestry of
      adaptations. Their long legs, resembling pillars of resilience, are built
      for speed, allowing them to sprint at astonishing speeds, making them the
      fastest land birds on the planet.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their wings, once thought vestigial, have evolved into graceful
      stabilizers, aiding in balance, and steering during their rapid runs. And
      then, there are those large, expressive eyes, pools of intelligence that
      hold the secrets of the ages. With a beak that defies their immense size,
      ostriches are meticulous foragers, savouring everything from succulent
      plants to small insects.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Yet, the most captivating feature of these birds is their remarkable
      plumage, a patchwork of feathers that blends earthy browns and blacks into
      a harmonious mosaic. Each feather, a masterpiece of nature's artistry,
      weaves a story of survival and adaptation.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Ostriches, in all their peculiarity and magnificence, are an emblem of
      Africa's boundless diversity, a symbol of the continent's ability to forge
      the extraordinary out of the ordinary. To witness an ostrich, a living
      relic of ancient avian giants, is to glimpse into the depths of nature's
      ingenuity and the grandeur of the African wilderness.
    </Typography>
  </>
);

export default Ostrich;
