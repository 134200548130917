import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import logo from "../assets/logo500x200.png";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const TopBar = () => {

  const location = useLocation();
  const [ activeKey, setActiveKey ] = useState(location.pathname);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home" className="brand">
          <Image src={logo} width={100} /> <span>Olive Creek Safaris</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ms-auto"
            activeKey={activeKey}
            onSelect={(selectedKey) => setActiveKey(selectedKey?.toString() ?? "home")}
          >
            <Nav.Link eventKey="/" href="/">
              Home
            </Nav.Link>
            <Nav.Link eventKey="/hunting" href="/hunting">
              Hunting
            </Nav.Link>
            <Nav.Link eventKey="/accommodation" href="/accommodation">
              Accommodation
            </Nav.Link>
            <Nav.Link eventKey="/activities" href="/activities/#activities">
              Activities
            </Nav.Link>
            <Nav.Link eventKey="/contactUs/" href="/contactUs/#contactUs">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar;
