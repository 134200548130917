import { Typography } from "@mui/material";

const Giraffe = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Meet the giraffe, an extraordinary inhabitant of the African savannas that
      captures the imagination with its graceful and towering presence. As the
      tallest land animal on Earth, giraffes command attention, boasting an
      impressive height of up to 18 feet.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their iconic long necks, which can measure around 6 feet in length, allow
      them to access leaves high in the treetops that are out of reach for other
      herbivores.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      With distinctive patches covering their bodies, giraffes sport a unique
      coat pattern that is as individual as a fingerprint. These patterns are
      not just for looks; they serve as effective camouflage in their habitat.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The giraffe's specialized cardiovascular system prevents blood from
      rushing to their heads when they lower them to drink or feed, and their
      hearts can be up to 2 feet long and weigh around 25 pounds.
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Social creatures, giraffes often form loose groups called towers,
      comprised of females and their offspring. Males engage in "necking"
      battles, where they use their powerful necks to establish dominance.
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Witnessing a giraffe gracefully moving across the savanna, with its
      distinctive slow-motion gait, is a captivating sight that exemplifies the
      wonders of the animal kingdom and the beauty of Africa's landscapes.
    </Typography>
  </>
);

export default Giraffe;
