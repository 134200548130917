import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/material";
import { ReactNode, useState } from "react";

function LimitedTextView({
  node,
  height,
}: {
  node: ReactNode;
  height: string;
}) {
  const [showMore, setShowMore] = useState(false);

  const toggleText = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <Box
        sx={{
          maxHeight: showMore ? "auto" : height,
          overflowY: showMore ? "none" : "hidden",
        }}
      >
        {node}
      </Box>
      <Button
        variant="text"
        onClick={toggleText}
        startIcon={
          <FontAwesomeIcon
            style={{ height: "16px" }}
            icon={showMore ? faChevronUp : faChevronDown}
          />
        }
        sx={{
          margin: "auto",
          display: "flex",
          textTransform: "none",
          color: "#5F869E",
          fontWeight: 600,
          marginBottom: "1rem",
          marginTop: "0.5rem"
        }}
      >
        {showMore ? "Show less" : "Show more"}
      </Button>
    </div>
  );
}

export default LimitedTextView;