import { Typography } from "@mui/material";

const Bushbuck = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The bushbuck is a captivating antelope species that thrives in the dense
      woodlands and forests of sub-Saharan Africa. With its enigmatic and
      solitary nature, it often finds solace amidst the thickets and underbrush,
      seeking refuge from predators and foraging for sustenance. The bushbuck
      presents a stunning appearance that varies slightly across its
      wide-ranging habitat. They are adorned with a rich chestnut to
      reddish-brown coat, embellished with intricate white spots and stripes on
      their flanks and back. This striking coat serves as impeccable camouflage
      within their woodland domain. Both males and females sport short,
      elegantly spiralled horns that gracefully curve backward, though the
      females possess smaller and more slender horns. Bushbucks are renowned for
      their elusive habits, chiefly active during the crepuscular hours of dawn
      and dusk. They sustain themselves on an herbivorous diet, feasting on
      leaves, fruits, and various vegetation.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One intriguing feature of the bushbuck is its remarkable ability to jump,
      gracefully leaping through thick underbrush and over obstacles, even when
      pursued by predators. They are also known for their distinct alarm call, a
      loud and raspy sound that warns other animals of potential danger in the
      area. Bushbucks are not just masters of camouflage but also experts in
      scent-marking, using preorbital glands on their face to leave scent marks
      on branches and vegetation to communicate with other bushbucks. Their
      elusive nature, striking appearance, and the challenges of spotting them
      in their natural habitat make encounters with bushbucks a cherished
      experience for wildlife enthusiasts and adventurers exploring Africa's
      breathtaking natural realms.
    </Typography>
  </>
);

export default Bushbuck;
