import { Typography } from "@mui/material";


const Civet = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The African Civet is a remarkable mammal indigenous to the diverse
      landscapes of sub-Saharan Africa. It exhibits a striking and distinctive
      appearance that sets it apart from many other animals in its range. These
      civets have a long, slender body that is covered in coarse fur. Their fur
      coloration is predominantly creamy white, providing a stark contrast to
      the dark markings that adorn their body.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The most prominent feature of the African Civet's appearance is the
      intricate pattern of dark spots, stripes, and blotches that decorate its
      fur. These markings are not only aesthetically striking but also serve as
      effective camouflage in their natural habitats, allowing them to blend
      into the dappled sunlight and shadows of the African woodlands and
      savannahs. This cryptic coloration is particularly useful for a primarily
      nocturnal creature like the African Civet.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their head is small and rounded, with a pointed snout and a set of sharp,
      carnivorous teeth adapted for hunting. Their eyes are relatively large and
      feature vertical slits in their pupils, a characteristic commonly found in
      nocturnal animals. African Civets also possess prominent, rounded ears
      that are keenly attuned to the sounds of their surroundings.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Overall, the African Civet's striking appearance, with its creamy fur and
      intricate dark markings, makes it an intriguing and captivating species
      within the rich tapestry of African wildlife.
    </Typography>
  </>
);

export default Civet;
