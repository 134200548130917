import { Typography } from "@mui/material";

const Blesbuck = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The Blesbuck is a remarkable antelope species endemic to the grassy plains
      and savannahs of South Africa. Its name is derived from the striking white
      blaze that adorns its forehead and face, a feature that sets it apart in
      the realm of African antelopes. The Blesbok's coat is a rich and lustrous
      reddish-brown to chestnut-brown, further accentuating the contrast with
      its prominent white facial markings.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      In terms of size and build, Blesboks are medium-sized antelopes, with
      males being larger and more robust than females. They have a graceful yet
      sturdy build. Both males and females possess distinct, ringed horns that
      curve backward and slightly upward. The horns, add to the Blesbok's
      captivating appearance.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Behaviourally, Blesboks are social creatures and are often observed in
      herds, ranging from small family groups to larger congregations,
      particularly in protected areas and game reserves. Their preferred
      habitats are open grasslands and savannahs, where they thrive as grazers,
      feeding on a diet primarily composed of grasses and other vegetation.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Reproduction in Blesboks is not bound to a specific season, with births
      occurring throughout the year. However, the rainy season often corresponds
      to a peak in births. Female Blesboks give birth to a single calf at a time
      and tend to hide their young in tall grass or concealed spots to protect
      them from potential predators.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Conservationally, Blesboks are considered a species of least concern, with
      stable populations. They are frequently encountered in South Africa's
      protected areas and game reserves, making them a cherished sight for
      wildlife enthusiasts and visitors exploring the region's natural beauty.
      The Blesbok's captivating coloration and graceful presence contribute to
      its status as an iconic emblem of the South African savannahs.
    </Typography>
  </>
);

export default Blesbuck;
