import { Typography } from "@mui/material";

const Reedbuck = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The common reedbuck is a striking antelope species native to the
      grasslands and wetlands of sub-Saharan Africa. It derives its name from
      its preferred habitat, often found in dense reed beds and tall grasses
      near water sources. They exhibit sexual dimorphism, with males sporting
      short, sharp, and ridged horns, while females are hornless.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Common reedbucks are known for their distinct appearance, featuring a
      reddish-brown to greyish-brown coat with a lighter underbelly and a white
      patch on their throat and chin. They possess large, rounded ears that
      enhance their keen sense of hearing, vital for detecting predators in
      their often densely vegetated habitats. These reclusive antelopes are
      typically solitary or found in small family groups, making them
      challenging to spot in the wild.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their diet primarily consists of grasses and herbs, with occasional
      browsing on shrubs. They are crepuscular, meaning they are most active
      during dawn and dusk, which helps them avoid the intense heat of midday
      and potential predators. Common reedbucks are excellent swimmers and are
      often observed wading through marshy areas.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Despite their elusive nature, common reedbucks are important for ecosystem
      health. They help control grasses through grazing, promote seed dispersal,
      and provide a critical food source for predators. Their presence enriches
      the tapestry of African grasslands and wetlands, making them an integral
      part of the continent's natural heritage.
    </Typography>
  </>
);

export default Reedbuck;
