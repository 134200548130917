import { Typography } from "@mui/material";

const Sable = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The sable antelope, a regal and captivating presence in the African
      wilderness, embodies both strength and grace. Their glossy, ebony coats,
      contrasting strikingly with their white facial markings, create a visage
      of timeless elegance. Majestic, spiralled horns, present in both males and
      females, curve regally backward and then sweep upward, embodying a sense
      of power and grandeur.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      These enchanting creatures roam the savannahs and woodlands of southern
      and eastern Africa with an air of dignity, as if they are custodians of a
      bygone era.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Sables are known for their spirited and agile movements, and their sharp,
      intelligent eyes hold the secrets of the vast African landscapes they call
      home. Their sociable nature often leads them to form small, close-knit
      groups, where vigilant sentinels stand guard while others graze. These
      antelopes possess a palpable aura of strength and nobility, and their
      presence in the wilds of Africa evokes a sense of awe and reverence for
      the continent's diverse and enduring beauty.
    </Typography>
  </>
);

export default Sable;
