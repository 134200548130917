import { Typography } from "@mui/material";

const Kudu = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The greater kudu is a majestic and iconic antelope species that inhabits
      the woodlands and savannas of eastern and southern Africa. Renowned for
      its stunning appearance, the greater kudu is one of the largest antelopes
      on the continent. Their horns are elegant and spiralled These magnificent
      horns, are exclusive to males and have prominent twists, making them an
      awe-inspiring sight. Females are smaller and lack horns.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The greater kudu's coat varies in colour, ranging from reddish-brown to
      greyish blue, with prominent white stripes running vertically down their
      sides, helping to break up their silhouette in the dappled light of their
      woodland habitats. These antelopes are renowned for their graceful,
      spiralling horns, which can have up to two and a half twists and are among
      the longest of any antelope species. The greater kudu is a browser,
      feeding on leaves, shoots, and fruits, and their keen sense of hearing and
      acute eyesight help them detect predators like lions and leopards in their
      often densely vegetated habitats.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      These antelopes are typically solitary or found in small, gender-separated
      groups. They are known for their elusive and cautious behaviour, often
      retreating into dense vegetation when threatened. The greater kudu's
      majestic presence and impressive horns make them a symbol of Africa's wild
      beauty and a prized sight for wildlife enthusiasts and photographers
      fortunate enough to encounter them in the wild.
    </Typography>
  </>
);

export default Kudu;
