import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { useMobile } from "../hooks/use-mobile.hook";

export default function MediaCard({
  src, title, description, link,
}: {
  src: string;
  title: string;
  description: string;
  link: string;
}) {
  const isMobile = useMobile();
  return (
    <Card sx={{ maxWidth: "100%", height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CardMedia
          sx={{ height: 160, backgroundPosition: "50% 0%" }}
          image={src}
          title="green iguana"
        />
        <CardContent style={{ flex: 1 }} sx={{padding: isMobile ? 1 : 2}}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{
              maxHeight: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 7, // Number of lines to show before ellipsis
              WebkitBoxOrient: "vertical",
              whiteSpace: 'pre-line'
            }}
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          {link ? (
            <NavLink to={link}>
              <Button sx={{ textTransform: 'none' }} size="small" className="blue">
                Learn more
              </Button>
            </NavLink>
          ) : null}
        </CardActions>

      </div>
    </Card>
  );
}
