import { Container, Row, Col } from "react-bootstrap";

import ElandsKloof1 from "../../assets/images/units/ElandsKloof/e1.jpg";
import ElandsKloof2 from "../../assets/images/units/ElandsKloof/e2.jpg";
import ElandsKloof3 from "../../assets/images/units/ElandsKloof/e3.jpg";
import ElandsKloof4 from "../../assets/images/units/ElandsKloof/e4.jpg";
import ElandsKloof5 from "../../assets/images/units/ElandsKloof/e5.jpg";
import ElandsKloof6 from "../../assets/images/units/ElandsKloof/e6.jpg";
import ElandsKloof7 from "../../assets/images/units/ElandsKloof/e7.jpg";
import ElandsKloof8 from "../../assets/images/units/ElandsKloof/e8.jpg";
import ElandsKloof9 from "../../assets/images/units/ElandsKloof/e9.jpg";
import ElandsKloof10 from "../../assets/images/units/ElandsKloof/e10.jpg";
import ElandsKloof11 from "../../assets/images/units/ElandsKloof/e11.jpg";
import ElandsKloof12 from "../../assets/images/units/ElandsKloof/e12.jpg";
import ElandsKloof13 from "../../assets/images/units/ElandsKloof/e13.jpg";
import ElandsKloof14 from "../../assets/images/units/ElandsKloof/e14.jpg";
import ElandsKloof15 from "../../assets/images/units/ElandsKloof/e15.jpg";
import ElandsKloof16 from "../../assets/images/units/ElandsKloof/e16.jpg";
import ElandsKloof17 from "../../assets/images/units/ElandsKloof/e17.jpg";

import Blouwildebees2 from "../../assets/images/units/BlouwildebeesUitsig/bwb1.jpg";
import Blouwildebees3 from "../../assets/images/units/BlouwildebeesUitsig/bwb2.jpg";
import Blouwildebees4 from "../../assets/images/units/BlouwildebeesUitsig/bwb3.jpg";
import Blouwildebees5 from "../../assets/images/units/BlouwildebeesUitsig/bwb4.jpg";
import Blouwildebees6 from "../../assets/images/units/BlouwildebeesUitsig/bwb5.jpg";
import Blouwildebees7 from "../../assets/images/units/BlouwildebeesUitsig/bwb6.jpg";
import Blouwildebees8 from "../../assets/images/units/BlouwildebeesUitsig/bwb7.jpg";
import Blouwildebees9 from "../../assets/images/units/BlouwildebeesUitsig/bwb8.jpg";
import Blouwildebees10 from "../../assets/images/units/BlouwildebeesUitsig/bwb9.jpg";
import Blouwildebees11 from "../../assets/images/units/BlouwildebeesUitsig/bwb10.jpg";
import Blouwildebees12 from "../../assets/images/units/BlouwildebeesUitsig/bwb11.jpg";
import Blouwildebees13 from "../../assets/images/units/BlouwildebeesUitsig/bwb12.jpg";
import Blouwildebees15 from "../../assets/images/units/BlouwildebeesUitsig/bwb13.jpg";
import Blouwildebees16 from "../../assets/images/units/BlouwildebeesUitsig/bwb14.jpg";
import Blouwildebees17 from "../../assets/images/units/BlouwildebeesUitsig/bwb15.jpg";
import Blouwildebees18 from "../../assets/images/units/BlouwildebeesUitsig/bwb16.jpg";
import Blouwildebees19 from "../../assets/images/units/BlouwildebeesUitsig/bwb17.jpg";
import Blouwildebees20 from "../../assets/images/units/BlouwildebeesUitsig/bwb18.jpg";
import Blouwildebees21 from "../../assets/images/units/BlouwildebeesUitsig/bwb19.jpg";
import Blouwildebees22 from "../../assets/images/units/BlouwildebeesUitsig/bwb20.jpg";
import Blouwildebees23 from "../../assets/images/units/BlouwildebeesUitsig/bwb21.jpg";
import Blouwildebees24 from "../../assets/images/units/BlouwildebeesUitsig/bwb22.jpg";
import Blouwildebees25 from "../../assets/images/units/BlouwildebeesUitsig/bwb23.jpg";
import Blouwildebees26 from "../../assets/images/units/BlouwildebeesUitsig/bwb24.jpg";
import Blouwildebees27 from "../../assets/images/units/BlouwildebeesUitsig/bwb25.jpg";
import Blouwildebees28 from "../../assets/images/units/BlouwildebeesUitsig/bwb26.jpg";
import Blouwildebees29 from "../../assets/images/units/BlouwildebeesUitsig/bwb27.jpg";
import Blouwildebees30 from "../../assets/images/units/BlouwildebeesUitsig/bwb28.jpg";

import {
  faBed,
  faCreditCard,
  faFireBurner,
  faHandHoldingDroplet,
  faHiking,
  faLaptop,
  faMountain,
  faPaw,
  faRestroom,
  faShower,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import Gallery from "../Gallery/Gallery";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMobile } from "../../hooks/use-mobile.hook";

const ElandsKloofImages = [
  ElandsKloof1,
  ElandsKloof2,
  ElandsKloof3,
  ElandsKloof4,
  ElandsKloof5,
  ElandsKloof6,
  ElandsKloof7,
  ElandsKloof8,
  ElandsKloof9,
  ElandsKloof10,
  ElandsKloof11,
  ElandsKloof12,
  ElandsKloof13,
  ElandsKloof14,
  ElandsKloof15,
  ElandsKloof16,
  ElandsKloof17,
];

const BlouwildebeesImages = [
  Blouwildebees30,
  Blouwildebees2,
  Blouwildebees3,
  Blouwildebees4,
  Blouwildebees5,
  Blouwildebees6,
  Blouwildebees7,
  Blouwildebees8,
  Blouwildebees9,
  Blouwildebees10,
  Blouwildebees11,
  Blouwildebees12,
  Blouwildebees13,
  Blouwildebees15,
  Blouwildebees16,
  Blouwildebees17,
  Blouwildebees18,
  Blouwildebees19,
  Blouwildebees20,
  Blouwildebees21,
  Blouwildebees22,
  Blouwildebees23,
  Blouwildebees24,
  Blouwildebees25,
  Blouwildebees26,
  Blouwildebees27,
  Blouwildebees28,
  Blouwildebees29,
];

const icons = [
  faPaw,
  faBed,
  faRestroom,
  faHandHoldingDroplet,
  faFireBurner,
  faMountain,
  faHiking,
  faCreditCard,
  faWifi,
  faLaptop,
  faShower,
];

const AccommodationGallery = () => {
  const isMobile = useMobile();
  return (
    <>
      <div className={"accommodation-gallery gallery"}>
        <Container>
          <Row>
            <h3 className="section-title">Accomodation</h3>
          </Row>
        </Container>
        <div className="accommodation-card">
          <Container>
            <Row
              className={"accommodation-gallery gallery"}
              style={{ backgroundColor: "white" }}
            >
              {!isMobile && (
                <Col>
                  <Gallery images={ElandsKloofImages} />
                </Col>
              )}
              <Col>
                <Typography variant="h5">ElandsKloof </Typography>
                <Typography variant="h6" sx={{ color: "rgb(95, 134, 158)" }}>
                  3 bedroom unit, 2 bathroom, wifi, large living space
                </Typography>

                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  The ElandsKloof accommodation unit offers a large living area,
                  a jacuzzi, river-front views with the ability to fish with own
                  fishing gear, a deck and free WiFi. The unit has a fully
                  equipped kitchen and a dining area, a living room with a sofa,
                  and two private bathrooms with a hairdryer, bathtub and
                  shower. Enjoy the beautiful view of Elande eating close to
                  your unit.
                </Typography>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {icons.map((icon) => (
                    <FontAwesomeIcon
                      key={icon.iconName}
                      icon={icon}
                      style={{ marginRight: 15, color: "#2F3E46" }}
                    />
                  ))}
                </Typography>
              </Col>
              {isMobile && (
                <Col>
                  <Gallery images={ElandsKloofImages} />
                </Col>
              )}
            </Row>
          </Container>
        </div>
        <div className="accommodation-card">
          <Container>
            <Row
              className={"accommodation-gallery gallery"}
              style={{ backgroundColor: "white" }}
            >
              <Col>
                <Typography variant="h5"> Blouwildebees Uitsig </Typography>
                <Typography variant="h6" sx={{ color: "rgb(95, 134, 158)" }}>
                  4 bedroom unit, 1.5 bathroom, airconditioning, wifi
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  The Blouwildebees Uitsig accommodation unit offers a large
                  living area, a patio, airconditioning, a jacuzzi and free
                  WiFi. The unit has a fully equipped kitchen, a living room
                  with a sofa, and two private bathrooms with a hairdryer,
                  bathtub and shower. Enjoy the beautiful view of Hippos eating
                  close to your unit.
                </Typography>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {icons.map((icon, index) => (
                    <FontAwesomeIcon
                      key={icon.iconName}
                      icon={icon}
                      style={{ marginRight: 15, color: "#2F3E46" }}
                    />
                  ))}
                </Typography>
              </Col>
              <Col>
                <Gallery images={BlouwildebeesImages} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AccommodationGallery;
