import { Typography } from "@mui/material";

const Springbuck = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The springbok is a fascinating and iconic antelope species that calls the
      vast arid plains and grasslands of southern Africa home. Known for its
      remarkable agility and distinctive appearance, the springbok is a
      testament to nature's ingenuity in the face of challenging environments.
      While they are not among the largest antelopes, their slender build and
      graceful proportions make them stand out.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One of the most striking features of the springbok is its elegant,
      lyre-shaped horns. Both males and females possess these horns. The horns
      are often curved and pointed, adding to the springbok's distinctive
      profile. Their reddish-brown to light-brown coat is marked with a striking
      white underbelly and a broad, dark brown stripe that runs from the eyes
      down the sides to the upper legs, creating a visually striking facial
      mask.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Springboks are renowned for their remarkable leaping ability, capable of
      making spectacular high jumps known as "pronking" or "stotting" when
      startled or during displays of vitality. These displays, combined with
      their agile movements, have earned them the nickname "Africa's ballet
      dancers." Springboks are primarily grazers, feeding on a diet of grasses
      and low-lying vegetation, and their adaptability to arid environments is a
      testament to their resilience. These captivating antelopes are not only a
      symbol of the African wilderness but also a remarkable example of nature's
      ability to thrive in diverse and challenging ecosystems.
    </Typography>
  </>
);

export default Springbuck;
