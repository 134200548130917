import {
  faEnvelope,
  faHouse,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import ImgMap from "../../assets/images/map.png";
import { Box, Stack, Typography } from "@mui/material";
import { useMobile } from "../../hooks/use-mobile.hook";

const containerStyle = {
  marginTop: "30px",
  width: "800px",
  height: "400px",
};

const center = {
  lat: -24.523552154791467,
  lng: 28.512370368549018,
};

const ContactUs = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const [map, setMap] = React.useState(null);
  const isMobile = useMobile();

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <Stack
      id="contactUs"
      justifyContent="start"
      alignItems="center"
      gap={4}
      sx={{ paddingY: 4 }}
    >
      <Typography variant="h4">Contact Us</Typography>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "start" : "center"}
        gap={isMobile ? 1 : 2}
        className="contact-section"
        sx={{
          fontWeight: 500,
          "& svg": { color: "rgb(95, 134, 158)", fontWeight: 600 },
        }}
      >
        <p>
          <FontAwesomeIcon icon={faPhone} /> &nbsp; +27 71 872 4227 / +1 (620) 377 7142
        </p>
        <p>
          <FontAwesomeIcon icon={faEnvelope} /> &nbsp; bookings@olivecreeksafaris.com
        </p>
        <p>
          <FontAwesomeIcon icon={faHouse} /> &nbsp; Naaupoort 203 kr, Modimolle, 0510
        </p>
      </Stack>
      {/* <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}

                > */}
      {/* Child components, such as markers, info windows, etc. */}
      {/* <></>
                </GoogleMap> */}
      <a
        href="https://www.google.com/maps/place/Wilgeboomsdrift+Safaris/@-24.5204992,28.5131624,15.83z/data=!4m9!3m8!1s0x1ebf5bbe0d6e24dd:0xdf62169794a67dd0!5m2!4m1!1i2!8m2!3d-24.5236449!4d28.5123811!16s%2Fg%2F11fpslj5wb?entry=ttu"
        target="_blank"
      >
        <Box
          sx={{
            width: "500px",
            filter: "grayscale(100%)",
            "&:hover": { opacity: 0.7 },
          }}
        >
          <img src={ImgMap} alt="" width="100%" />
        </Box>
      </a>
    </Stack>
  ) : (
    <></>
  );
};

export default ContactUs;
