import { Typography } from "@mui/material";

const Impala = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Impalas are elegant and agile antelope species that inhabit various
      regions of eastern and southern Africa. These medium-sized herbivores
      exhibit striking features and adaptability to their environment. Their
      tail is marked with a characteristic white tuft at the rear.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Impalas are known for their reddish-brown coats, which exhibit a glossy
      sheen, and they feature a striking black "M" shape on their rear, which
      stands out prominently when they flee from predators. Their graceful,
      lyre-shaped horns are exclusive to males, curving upward and backward
      These horns are used in territorial displays and can also serve as a means
      of defence against predators.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Impalas are highly adaptable herbivores, feeding on a variety of
      vegetation, including grasses, leaves, shoots, and fruits. They are
      well-known for their remarkable agility and incredible leaping abilities,
      capable of making spectacular high jumps when evading predators. These
      social antelopes are often found in large mixed-sex herds, where vigilant
      females help protect each other's offspring from potential threats.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their adaptability, impressive athleticism, and striking appearance make
      impalas a common sight and a symbol of Africa's vast and diverse savannah
      landscapes.
    </Typography>
  </>
);

export default Impala;
