import {
  Avatar,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";
import LimitedTextView from "../LimitedTextView";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useMobile } from "../../hooks/use-mobile.hook";

const AnimalInfo = ({
  info,
  children,
}: {
  info: {
    title: string;
    component: ReactNode;
    src: string;
    huntingSrc?: string[];
    scientificName: string;
    size: string;
    weight: string;
    hornSize?: string;
    tusks?: string;
    variety?: string;
  };
  children: ReactNode;
}) => {
  const isMobile = useMobile();
  
  return (
    <Box >
      {/* <Stack direction="row" gap={2} alignItems="center">
        <Avatar
          alt={info.title}
          src={info.src}
          variant="rounded"
          sx={{ width: 56, height: 56 }}
        />
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {info.title}
        </Typography>
      </Stack> */}

      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        gap={4}
        sx={{ width: "100%", marginBottom: 1 }}
      >
        <Stack direction="row" gap={2} alignItems="center">
          <Avatar
            alt={info.title}
            src={info.src}
            variant="rounded"
            sx={{ width: 150, height: 150 }}
          />
        </Stack>

        <Grid container spacing={0} sx={{ marginTop: 1, marginBottom: 2 }}>
          <Grid xs={12} sx={{ fontWeight: 600 }}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              {info.title}
            </Typography>
          </Grid>
          <Grid xs={6} lg={3} sx={{ fontWeight: 600 }}>
            Scientific name
          </Grid>
          <Grid xs={6} lg={9}>
            {info.scientificName}
          </Grid>
          <Grid xs={6} lg={3} sx={{ fontWeight: 600 }}>
            Size
          </Grid>
          <Grid xs={6} lg={9}>
            {info.size}
          </Grid>
          <Grid xs={6} lg={3} sx={{ fontWeight: 600 }}>
            Weight
          </Grid>
          <Grid xs={6} lg={9}>
            {info.weight}
          </Grid>
          {info.variety && (
            <>
              <Grid xs={6} lg={3} sx={{ fontWeight: 600 }}>
                Variety
              </Grid>
              <Grid xs={6} lg={9}>
                {info.variety}
              </Grid>
            </>
          )}
          {info.hornSize && (
            <>
              <Grid xs={6} lg={3} sx={{ fontWeight: 600 }}>
                Horn size
              </Grid>
              <Grid xs={6} lg={9}>
                {info.hornSize}
              </Grid>
            </>
          )}
          {info.tusks && (
            <>
              <Grid xs={6} lg={3} sx={{ fontWeight: 600 }}>
                Tusk size
              </Grid>
              <Grid xs={6} lg={9}>
                {info.tusks}
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
      <LimitedTextView node={children} height={"200px"} />
      <ImageList
        sx={{ width: "100%", height: "auto" }}
        cols={isMobile ? 2 : 3}
        rowHeight={200}
      >
        {(info.huntingSrc ?? []).map((key: string) => (
          <ImageListItem key={key} sx={{ "& img": { maxHeight: "200px" } }}>
            <img
              src={`${key}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${key}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={info.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default AnimalInfo;
