import { Typography } from "@mui/material";

const Klipspringer = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Klipspringers are unique and captivating antelope species that inhabit
      rocky and mountainous regions across eastern and southern Africa. These
      diminutive herbivores are renowned for their incredible adaptations to
      their rugged habitats. Klipspringers are among the smallest antelopes.
      They have short, stocky bodies with a reddish-brown to greyish-brown coat,
      adorned with a distinctive white, bristle-like crest of hair that runs
      along their back. Their name, which means "rock jumper" in Afrikaans,
      aptly describes their remarkable agility and ability to traverse steep and
      rocky terrain with ease.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One of the most fascinating features of klipspringers is their unique
      hooves, which are adapted for surefootedness on rocky outcrops. The bottom
      of their hooves is concave with a rubbery core, allowing them to grip and
      balance on even the narrowest ledges. Klipspringers are typically
      monogamous, forming lifelong pairs and occupying territories within their
      rocky habitats. They are browsers, feeding on a variety of vegetation,
      including leaves, shoots, and grasses, often supplementing their diet with
      mineral-rich licks found in their rocky homes.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Klipspringers' small size, incredible agility, and specialized adaptations
      make them perfectly suited for their rocky habitats, where they can find
      refuge from predators and access vegetation that larger herbivores cannot
      reach. Their charming presence and unique adaptations make klipspringers a
      prized sighting for wildlife enthusiasts exploring Africa's rugged
      landscapes.
    </Typography>
  </>
);

export default Klipspringer;
