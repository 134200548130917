import { useState, useEffect } from 'react';

export function useMobile() {
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      // Check if the viewport width is less than a certain threshold (e.g., 768 pixels)
      const checkIsMobile = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      // Listen to window resize events to update isMobile
      window.addEventListener('resize', checkIsMobile);
  
      // Initial check when the component mounts
      checkIsMobile();
  
      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', checkIsMobile);
      };
    }, []);
  
    return isMobile;
  }