import { Typography } from "@mui/material";

const Hippo = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Introducing the hippopotamus, a captivating and enigmatic creature native
      to the rivers and lakes of Africa. With its massive build and amphibious
      lifestyle, the hippo is a true marvel of the animal kingdom.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Weighing up to a staggering 2,000 to 3,000 pounds, these behemoths possess
      an unexpected agility, capable of impressive speed both in water and on
      land.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their thick, hairless skin, often tinted pink due to exposure to the sun,
      serves as protection from the African heat. The hippo's most iconic
      feature is undoubtedly its wide-mouthed grin, revealing formidable tusks
      that can grow up to 20 inches in length
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Despite their seemingly gentle demeanor, hippos are known as one of the
      most dangerous animals in Africa, making them a subject of both fear and
      fascination. Spending the majority of their days submerged in water to
      keep their massive bodies cool, hippos emerge at night to graze on grasses
      near water sources. As creatures of contrast, these semi-aquatic giants
      offer a glimpse into the harmonious yet perilous coexistence between
      humanity and nature in Africa's waterways.
    </Typography>
  </>
);

export default Hippo;
