import NeedToKnow from "./NeedToKnow";
import AnimalListInfo from "./AnimalListInfo";
import { Box, Container, Typography, Grid } from "@mui/material";
import { useMobile } from "../../hooks/use-mobile.hook";

const HuntingPage = () => {
  const isMobile = useMobile();
  return (
    <>
      {isMobile && <Box>
        <Container sx={{ paddingTop: 3, maxWidth: "100vw" }}>
          <Typography variant="h4" >
            Hunting
          </Typography>
        </Container>
      </Box>}
      <Grid container spacing={2}>
        <Grid item xs={12} id="info">
          <AnimalListInfo />
        </Grid>
        <Grid item xs={12}>
          <NeedToKnow />
        </Grid>
      </Grid>
    </>

  );
};

export default HuntingPage;
