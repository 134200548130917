import { Typography } from "@mui/material";

const Roan = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The Roan is a remarkable and stately herbivore that inhabits the woodlands
      and savannahs of sub-Saharan Africa. This antelope stands as one of the
      largest in its family. However, it is not just their size that makes them
      stand out; it is also their impressive horns.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Roan antelopes possess long, heavily ridged horns that gracefully sweep
      back and then curve forward at the tips, resembling formidable scimitars.
      Males typically having longer and more robust horns than females. These
      powerful horns are not just for show; they serve as formidable weapons and
      tools for defence against predators and in competition with other males
      during mating season.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their coat, varying from reddish-brown to a striking reddish Gray, is
      adorned with white facial markings and a distinctive black and white
      facial mask. The Roan antelope's dignified presence on the African
      savannah is a testament to the continent's diverse and enduring wildlife,
      where size, strength, and elegance converge in one magnificent species.
    </Typography>
  </>
);

export default Roan;
