import { Typography } from "@mui/material";

const Nyala = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The Nyala is a masterpiece of nature, an elegant and enchanting antelope
      that graces the dense woodlands and bushlands of southern Africa. With its
      distinctively marked, reddish-brown coat adorned by striking white
      vertical stripes and a gleaming, ivory face mask, the Nyala appears as if
      nature itself took up the paintbrush to craft a living work of art. Males,
      known as rams, are further embellished with impressive spiral horns,
      gracefully curving backward and upward, adding an air of regal splendour
      to their presence. In contrast, the females, or ewes, lack horns and exude
      a serene beauty of their own. these antelopes strike a balance between
      grace and strength.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Nyala are creatures of solitude, often preferring the tranquil solitude of
      their woodland habitat. They are meticulous browsers, delicately selecting
      leaves, twigs, and shrubs from the verdant tapestry of their environment.
      Nyala is more crepuscular by nature, gracefully emerging from the shadows
      of dawn and dusk, and their cautious demeanour lends an aura of mystery to
      their encounters in the wild.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      In the realm of African wildlife, Nyala stands as a testament to nature's
      artistry, a living canvas of earthy tones and striking patterns. To chance
      upon these captivating creatures is to witness a living masterpiece, a
      testament to the intricate and mesmerizing beauty of the natural world.
    </Typography>
  </>
);

export default Nyala;
