import { Typography } from "@mui/material";

const HoneyBadger = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The honey badger a remarkable member of the Mustelidae family, is a
      formidable and tenacious creature native to Africa, the Middle East, and
      the Indian subcontinent. Renowned for its fearless and pugnacious
      disposition, the honey badger is a stocky, medium-sized mammal with a
      distinctive look.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their coarse, grizzled fur varies in colour from Gray to brown, often with
      a conspicuous black dorsal stripe. Honey badgers have powerful jaws and
      sharp teeth that allow them to efficiently consume a varied diet that
      includes insects, small mammals, reptiles, and even plant matter. However,
      they are particularly famous for their remarkable predilection for bee
      larvae and honey, which they can access with ease using their strong claws
      and thick skin that provides protection from bee stings
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Despite their relatively small size, honey badgers have earned a fearsome
      reputation in the animal kingdom due to their exceptional strength,
      agility, and seemingly fearless nature. They are renowned for their
      confrontations with formidable adversaries such as lions, hyenas, and even
      venomous snakes. Their loose, tough skin allows them to twist and turn
      within their attackers' grip, enabling them to bite back with tenacity.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Honey badgers are primarily solitary and nocturnal creatures, often on the
      move in search of food during the night. Their intelligence and
      adaptability have made them survivors in a variety of environments, from
      grasslands and savannahs to forests and deserts.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The honey badger's reputation for its indomitable spirit and fearlessness
      in the face of danger has earned it a place in popular culture and the
      respect of many wildlife enthusiasts. This tough and resourceful creature
      serves as a symbol of resilience and adaptability in the wilds of Africa
      and beyond.
    </Typography>
  </>
);

export default HoneyBadger;
