import { Typography } from "@mui/material";

const Warthog = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The common warthog is a fascinating and distinctive member of Africa's
      wildlife community, known for its unique appearance and tenacious spirit.
      These robust swine-like creatures are often found in the savannahs,
      grasslands, and woodlands of sub-Saharan Africa. Warthogs are not the
      largest of Africa's mammals, but they certainly have a memorable presence.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One of their most striking features is the presence of warts or
      protuberances on their face, which resemble the warts often found on pigs.
      Males typically have larger warts than females, and these facial features
      give them a rugged and formidable appearance. Another standout
      characteristic of warthogs is their impressive, curved tusks, which
      protrude from their mouths. These tusks are elongated canine teeth and are
      used for digging, defence, and combat.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Warthogs are skilled excavators, using their sturdy snouts to root in the
      ground for tubers, bulbs, and plant roots, which make up a significant
      portion of their diet. They are also opportunistic grazers, feeding on
      grasses and other vegetation. Their burrowing skills allow them to create
      underground dens for shelter, where they retreat from predators and escape
      the scorching heat of the African sun.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Despite their comical appearance, warthogs are known for their speed and
      agility. When threatened, they can dash away at surprisingly high speeds,
      often zigzagging to evade predators like lions and hyenas. These hardy and
      adaptable animals are a testament to the resilience of African wildlife
      and play a vital role in their ecosystems, both as consumers of vegetation
      and as prey for larger predators.
    </Typography>
  </>
);

export default Warthog;
