import { Row, Col } from 'react-bootstrap';
import MediaCard from '../MediaCard';
import { Box, Container, Typography } from "@mui/material";
import { ActivitiesArray } from './ActivitieListInfo';
import { useMobile } from "../../hooks/use-mobile.hook";

const activitiesArray = ActivitiesArray;

const Activities = () => {
  const isMobile = useMobile();

  const renderActivities = () => {
    if (isMobile) {
      return activitiesArray.map((activity) => (
        <div key={activity.title} style={{ marginBottom: "20px" }}>
          <Row>
            <Col>
              <MediaCard
                src={activity.src}
                title={activity.title}
                description={activity.description}
                link=""
              ></MediaCard>
            </Col>
          </Row>
        </div>
      ));
    } else {
      return (
        <Row>
          {activitiesArray.map((activity) => (
            <Col key={activity.title}>
              <MediaCard
                src={activity.src}
                title={activity.title}
                description={activity.description}
                link=""
              ></MediaCard>
            </Col>
          ))}
        </Row>
      );
    }
  };

  return (
    <Box>
      <Container sx={{ paddingY: 3, maxWidth: "100vw" }}>
        <Typography variant="h4" sx={{ paddingY: 3 }}>
          Activities
        </Typography>
        {renderActivities()}
      </Container>
    </Box>
  );
};

export default Activities;
