import { Typography } from "@mui/material";

const Oryx = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The Gemsbok also known as the South African Oryx, is a striking and robust
      antelope species native to the arid and semi-arid regions of Southern
      Africa. These magnificent creatures are renowned for their striking
      appearance and formidable adaptations to harsh desert environments.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One of the most distinctive features of the Gemsbok is its long, straight,
      and slender horns. These impressive horns are a remarkable adaptation to
      their environment, extending backward from the head and curving slightly.
      And are ringed with prominent ridges. These horns not only serve as
      formidable weapons for self-defence but also play a crucial role in
      temperature regulation. Blood vessels within the horns dissipate excess
      body heat, allowing the Gemsbok to thrive in the intense desert heat.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their sandy to greyish-brown coats are marked by striking black facial and
      leg markings, adding to their visual appeal. Gemsboks are perfectly
      adapted to the harsh desert environment they call home. They are primarily
      herbivores, feeding on a diet of tough, thorny desert vegetation, which
      provides them with moisture and nutrients even in water-scarce regions.
      Their ability to conserve water and withstand high temperatures is
      remarkable, and they can survive without drinking water for extended
      periods.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      These antelopes are often seen in small family groups or solitary
      individuals, and their alert and cautious nature makes them a challenging
      but rewarding sight for wildlife enthusiasts and photographers. The
      Gemsbok's majestic presence in the arid landscapes of Southern Africa is a
      testament to nature's resilience and adaptation in the face of challenging
      conditions, making them a symbol of the desert's enduring spirit.
    </Typography>
  </>
);

export default Oryx;
