import { Typography } from "@mui/material";

const Eland = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Discover the eland, an awe-inspiring antelope species native to the
      diverse landscapes of Africa. As the largest antelope in the world, elands
      command attention with their impressive size, standing up to 6 feet tall
      at the shoulder and weighing between 900 to 2,200 pounds.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Both males and females sport striking, spiral-shaped horns that can reach
      lengths of up to 3 feet, adding to their distinctive appearance. These
      adaptable creatures thrive in a variety of habitats, from savannas to
      woodlands, and possess a remarkable endurance that sets them apart.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Elands exhibit unique social behavior, living in small herds of females
      and young, while males often lead solitary lives or form bachelor groups.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Their herbivorous diet encompasses grasses and leaves, thanks to their
      specialized digestive system. With cultural significance in African
      societies and a powerful vocalization that resonates across the landscapes
      they inhabit, the eland remains a captivating symbol of Africa's natural
      heritage.
    </Typography>
  </>
);

export default Eland;
