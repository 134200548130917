import { Typography } from "@mui/material";

const Waterbuck = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The waterbuck is a striking and resilient antelope species that thrives in
      the lush wetlands and savannahs of sub-Saharan Africa. These robust
      herbivores are known for their distinctive appearance and adaptations to
      their watery habitats. Waterbucks are robust and sizable animals, with
      males typically being larger and heavier than females.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      One of the most remarkable features of waterbucks is their shaggy,
      reddish-brown coat, which is often adorned with white markings on the
      throat and rear. They have a distinctive white ring encircling their rump,
      which serves as a striking contrast to their darker body colour. Male
      waterbucks are further embellished with long, curved horns. These
      impressive horns curve gracefully backward and then slightly forward at
      the tips, making them useful for defence and competition with other males
      during mating season.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Waterbucks are adapted to a semi-aquatic lifestyle, often inhabiting areas
      near rivers, lakes, and marshes. They have specialized glands that secrete
      an oily substance, which helps make their fur water-resistant. These
      antelopes are predominantly grazers, feeding on a variety of grasses and
      aquatic plants found in their watery habitats. Their combination of size,
      adaptations, and striking appearance makes the waterbuck a captivating
      presence in Africa's diverse ecosystems.
    </Typography>
  </>
);

export default Waterbuck;
