import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Introduction from "./components/Introduction";
import AccommodationGallery from "./components/Accommodation/AccommodationGallery";
import HuntingPage from "./components/hunting/Hunting";
import NotFound from "./components/NotFound";
import Layout from "./components/Layout";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Introduction />} />
            <Route path="hunting" element={<HuntingPage />} />
            <Route path="accommodation" element={<AccommodationGallery />} />
            <Route path="activities" element={<Introduction />} />
            <Route path="*" element={<NotFound />} />
            <Route path="contactUs" element={<Introduction />} />
          </Route>
         
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

/* @todo:
        Add parallax effect to different sections?
        Add accommodation images -> Accommodation should be split into 2 sections to show the 2 different units similar to www.wilgeboomsdrift.com
        We can add subsections later for different animals (for instance under hunting if you click on Buffalo it opens
          a page with title buffalo, explains what the african buffalo is, shows weight size etc. what is a trophy and images of buffaloes hunted)
        Update header nav items to be more relevant to the site ---DONE---
        Create pricing section for hunting and accomadation where people can download the pricing pdf
      */