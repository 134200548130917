import {
  ButtonGroup,
  Button,
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { ReactNode, useState } from "react";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { useMobile } from "../../hooks/use-mobile.hook";

const NeedToKnowSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{ textAlign: "left", marginBottom: 1, fontWeight: 600 }}
      >
        {title}
      </Typography>
      {children}
    </>
  );
};

const NeedToKnow = () => {
  const [section, setSection] = useState(0);
  const isMobile = useMobile();
  return (
    <Box sx={{ paddingY: 4, backgroundColor: "#F0F0F0" }}>
      <Typography variant={isMobile ? "h5": "h4"} sx={{ textAlign: "center", fontWeight: 500 }}>
        What you need to know
      </Typography>
      <Stack direction="row" justifyContent="center">
        <ButtonGroup
          variant="contained"
          size={isMobile ? "small" : "large"}
          sx={{
            marginTop: 2,
            marginBottom: 2,
            minWidth: isMobile ? "98%" : "80%",
            button: {
              minWidth: "25% !important",
              paddingX: isMobile ? 2 : 6,
              paddingY: isMobile ? 1 : 2,
              borderColor: "lightgrey !important",
              fontWeight: 500,
              textTransform: "none",
              fontSize: isMobile ? "0.8rem" : "1rem",
              "&:hover": {
                backgroundColor: "lightgrey",
                color: "black",
              },
            },
          }}
        >
          <Button
            sx={{
              backgroundColor: section === 0 ? "lightgrey" : "grey",
              color: section === 0 ? "black" : "white",
            }}
            onClick={() => setSection(0)}
          >
            Planning
          </Button>
          <Button
            sx={{
              backgroundColor: section === 1 ? "lightgrey" : "grey",
              color: section === 1 ? "black" : "white",
            }}
            onClick={() => setSection(1)}
          >
            Arrival
          </Button>
          <Button
            sx={{
              backgroundColor: section === 2 ? "lightgrey" : "grey",
              color: section === 2 ? "black" : "white",
            }}
            onClick={() => setSection(2)}
          >
            Hunting
          </Button>
          <Button
            sx={{
              backgroundColor: section === 3 ? "lightgrey" : "grey",
              color: section === 3 ? "black" : "white",
            }}
            onClick={() => setSection(3)}
          >
            Afterwards
          </Button>
        </ButtonGroup>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ minWidth: "80%", maxWidth: isMobile ? "98%" : "80%" }}>
          <CardContent>
            {section === 0 && (
              <NeedToKnowSection title="Planning">
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  Visas and Documentation
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                  Visitors hailing from the USA, as well as many European and
                  British Commonwealth nations, can enter South Africa without a
                  visa. To check the visa requirements for your specific
                  country, please visit:
                  <Button
                    sx={{ textTransform: "none", color: "#84A98C" }}
                    component="a"
                    target="_blank"
                    href="http://www.dha.gov.za/index.php/immigration-services/exempt-countries"
                  >
                    www.dha.gov.za&nbsp;
                    <OpenInNew fontSize="small" />
                  </Button>
                  {/* <Typography variant="anchor">
                    http://www.dha.gov.za/index.php/immigration-services/exempt-countries
                    </Typography> */}
                </Typography>
                <Typography variant="body2">
                  Children under the age of eighteen traveling to South Africa
                  have specific requirements. Alongside their passport, minors
                  must present an Unabridged Birth Certificate that includes
                  details of both parents when entering and leaving South
                  African ports of entry. In cases where a child is traveling
                  with only one parent, additional documents are necessary.
                  These include an affidavit from the absent parent granting
                  consent for the child's travel, a court order conferring full
                  parental responsibilities or legal guardianship, or the death
                  certificate of the absent parent.
                </Typography>
                <br />
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  Health
                </Typography>
                <Typography variant="body2">
                  Olive Creek Safaris is located in the Waterberg region of
                  Limpopo, South Africa, which falls within a malaria zone. As a
                  precautionary measure, it is advisable to take malaria
                  prophylaxis while visiting this area.
                </Typography>
                <br />
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  Firearms Permits
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                  Hunters traveling to South Africa must adhere to strict
                  regulations regarding firearms. Those engaging in hunting or
                  shooting events are required to obtain a Temporary Import
                  Permit, which can be acquired upon arrival at the airport from
                  the SA Police Firearm Office, located after immigration.
                  Alternatively, for a fee, several commercial services are
                  available to assist in securing the permit in advance.
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                  In order to obtain a permit, you must present evidence of
                  firearm ownership (for American hunters, a U.S. Customs Form
                  4457 will suffice) and an invitation letter from your
                  outfitter, confirming your booking for a hunt in South Africa.
                </Typography>
                <Typography variant="body2">
                  The allowance for each hunter is up to four firearms, with the
                  condition that no two firearms may have the same caliber.
                  Ammunition is limited to 200 rounds per firearm. For safe and
                  convenient travel, it is recommended to pack your ammunition
                  in a hard-sided, lockable case, as some airlines may require
                  separate checking of ammunition.
                </Typography>
              </NeedToKnowSection>
            )}
            {section === 1 && (
              <NeedToKnowSection title="Arrival">
                <>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Permits
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    Most hunters arriving from other countries and heading to
                    South Africa usually land in Johannesburg. Upon arrival,
                    they can retrieve any declared or identified firearms
                    directly from the firearm office after the immigration
                    process. Any firearms that haven't been declared during
                    departure or lack appropriate labels will be available for
                    collection at the regular baggage carousel.
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    After collecting your baggage, make your way to the SA
                    Police Firearm Office to complete all necessary import
                    permits and other required documentation. Keep in mind that
                    this process might take some time, especially if several
                    other hunters have arrived on the same flight. If you have a
                    connecting flight, it's advisable to schedule at least three
                    hours of layover to ensure sufficient time for the
                    procedures.
                  </Typography>

                  <Typography variant="body2">
                    For domestic connecting flights, you must clear your
                    firearms and ammunition at the SA Police Firearm Office in
                    the arrival airport before going through customs. To board
                    your domestic flight, you will need to present your
                    Temporary Import Permit. Please note that carrying firearms
                    on domestic flights is limited to specific airports within
                    South Africa, so it's essential to verify with your
                    outfitter whether you can check your firearm to your
                    intended destination.
                  </Typography>
                </>
              </NeedToKnowSection>
            )}
            {section === 2 && (
              <NeedToKnowSection title="Hunting">
                <>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Approach
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    The majority of hunting experiences in South Africa involve
                    spot-and-stalk or safari-style approaches. Hunters typically
                    traverse the hunting grounds either on foot or using
                    vehicles. Once an animal is sighted, the hunter proceeds to
                    approach it on foot, completing a stalk. The terrain varies
                    significantly, ranging from relatively flat savannas to
                    challenging mountainous regions, depending on the specific
                    area of the country being hunted.
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    The level of physical activity required during safaris can
                    differ widely. Some may involve extensive walking, while
                    others may require minimal effort. To gain clarity on the
                    physical demands of a particular safari, it is recommended
                    to inquire with the professional hunter or outfitter
                    facilitating the hunt.
                  </Typography>

                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Clothing and Gear
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    It is advisable to wear dark green or brown clothing during
                    the hunt, as these colors blend well with the surroundings.
                    Additionally, opt for comfortable and well-worn boots with
                    soft soles to ensure silent stalking. Packing a few changes
                    of clothing is sufficient, as many camps offer daily laundry
                    services.
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Other essential items to bring include a wide-brimmed hat,
                    sunblock to protect from the sun, high-quality optics for
                    improved visibility, and a jacket to keep warm during cool
                    mornings and evenings. In certain regions, pepper ticks can
                    be a concern, so it's wise to have tick repellent on hand
                    for added protection.
                  </Typography>

                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Rifles
                  </Typography>
                  <Typography variant="body2">
                    Professional hunters typically suggest using rifles within
                    the .300-caliber range for hunting plains game, and
                    .375-caliber rifles for dealing with dangerous game.
                    However, the crucial factor is to bring a rifle that you are
                    thoroughly acquainted with and can handle skillfully. Your
                    familiarity and proficiency with the firearm are paramount
                    for a successful hunting experience.
                  </Typography>
                </>
              </NeedToKnowSection>
            )}
            {section === 3 && (
              <NeedToKnowSection title="Afterwards">
                <>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Trophy Preperation
                  </Typography>
                  <Typography variant="body2">
                    Olive Creek Safaris offers comprehensive services for
                    handling hunted animals. Typically, animals are skinned at
                    their safari headquarters, and all skins undergo a
                    meticulous cleaning and preservation process involving
                    salting. The same care is given to cleaning and preserving
                    skulls and horns. After a brief drying period, the skins
                    receive an additional cleaning before being neatly folded
                    and stored in a specially designated skinning shed.
                    Likewise, the skulls are treated with great care, undergoing
                    thorough cleaning and being thoughtfully arranged on a rack
                    for display.
                  </Typography>
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Taxidermy
                  </Typography>
                  <Typography variant="body2">
                    After the hunter's departure, or sometimes at the conclusion
                    of the season, Olive Creek Safaris will take the trophies to
                    a taxidermist for professional cleaning and dipping, as it
                    is a necessary step for exporting them. The taxidermist will
                    then reach out to the hunter to discuss preparation and
                    shipment instructions, and to arrange payment for these
                    services. Subsequently, the taxidermist will contact a
                    shipping agent responsible for handling permits and shipping
                    the trophies to the hunter's home country, after
                    coordinating with the hunter. It is essential for hunters to
                    stay informed about the latest regulations, especially if
                    they are hunting species that require CITES permits or
                    special export/import permits in their home country.
                    Numerous export and import requirements for these species
                    have undergone significant changes in recent years, making
                    it crucial to plan ahead and make necessary arrangements.
                  </Typography>
                </>
              </NeedToKnowSection>
            )}
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default NeedToKnow;
