import { Typography } from "@mui/material";

const Wildebeest = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The wildebeest is a remarkable antelope species found in East and Southern
      Africa. These creatures possess a unique and easily recognizable
      appearance, characterized by their robust, humpbacked bodies, long legs,
      sharp, curved horns, and a shaggy, dark mane along their neck and
      shoulders. Affectionately referred to as the "clowns of the African
      savannah" due to their often comical and eccentric behaviours, wildebeests
      are renowned for their remarkable annual migration, often heralded as one
      of the most spectacular wildlife events globally.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      During the migration, millions of wildebeests, joined by zebras and other
      herbivores, embark on an arduous journey across the African plains in
      search of fresh grazing pastures. This massive movement spans hundreds of
      miles, presenting numerous challenges, including treacherous river
      crossings, where they face not only strong currents but also formidable
      predators like crocodiles and lions. This grand migration is a testament
      to both the wildebeests' adaptability and their role in shaping the
      ecological dynamics of their habitat.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Wildebeests inhabit a variety of environments, ranging from open
      grasslands to woodlands. Their foraging behaviour plays a crucial role in
      the ecosystem, as they graze on various types of vegetation, thereby
      helping to control plant growth and maintain the balance of their
      ecosystem. Additionally, they contribute to soil aeration through their
      hoof prints, enhancing soil health.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      This charismatic species of antelope is not only a symbol of the African
      wilderness but also a vital component of its intricate web of life. The
      wildebeest's survival and remarkable migration continue to captivate and
      inspire nature enthusiasts and conservationists worldwide, underscoring
      the profound importance of preserving their natural habitats for
      generations to come.
    </Typography>
  </>
);

export default Wildebeest;
