import { Typography } from "@mui/material";

const Zebra = () => (
  <>
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The Zebra is a quintessential icon of the African savannahs. With its
      distinctive black and white striped coat, it is one of the most
      recognizable and beloved animals on the continent.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The striking black and white stripes serve as an effective form of
      camouflage and protection, making it harder for predators like lions and
      hyenas to single out an individual in a group. Each zebra's stripe pattern
      is unique, much like human fingerprints.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Zebras are renowned for their remarkable herds, where they often gather in
      the hundreds or even thousands. These herds provide not only safety in
      numbers but also opportunities for mutual grooming, socializing, and
      protection from predators.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      Zebras are primarily grazers, feeding on a diet of grasses. They are
      constantly on the move, migrating in search of fresh grazing grounds and
      water sources. Their endurance and adaptability are essential for
      surviving in the dynamic and often challenging landscapes of Africa's
      grasslands and savannahs.
    </Typography>

    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      The zebra is not only a symbol of Africa's vast and vibrant wilderness but
      also a testament to the intricate beauty of nature's designs. Their iconic
      stripes, combined with their communal behaviours, make them a symbol of
      unity and diversity in the wild.
    </Typography>
  </>
);

export default Zebra;
