import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Divider, Grid } from "@mui/material";
import { Container } from "react-bootstrap";

import AnimalInfo from "./AnimalInfo";
import { useState } from "react";

/** Images */
import Buffalo2 from "../../assets/images/animals/buffalo2.jpg";
import ImgBaboon from "../../assets/images/animals/baboon.jpg";
import ImgBlesbuck from "../../assets/images/animals/blesbuck.png";
import ImgBushbuck from "../../assets/images/animals/bushbuck.jpg";
import ImgBushpig from "../../assets/images/animals/bushpig.jpg";
import ImgCivet from "../../assets/images/animals/civet.png";
import ImgDuiker from "../../assets/images/animals/duiker.jpg";
import ImgHoneyBadger from "../../assets/images/animals/honeybadger.jpg";
import ImgImpala from "../../assets/images/animals/impala.jpg";
import ImgJackal from "../../assets/images/animals/jackal.jpg";
import ImgKlipspringer from "../../assets/images/animals/klipspringer.jpg";
import ImgKudu from "../../assets/images/animals/kudu.jpg";
import ImgNyala from "../../assets/images/animals/nyala.jpg";
import ImgOryx from "../../assets/images/animals/oryx.jpg";
import ImgOstrich from "../../assets/images/animals/ostrich.png";
import ImgRedHartebeest from "../../assets/images/animals/redhartebeest.jpg";
import ImgReedbuck from "../../assets/images/animals/reedbuck.png";
import ImgRoan from "../../assets/images/animals/roan.jpg";
import ImgSable from "../../assets/images/animals/sable.jpg";
import ImgSpringbuck from "../../assets/images/animals/springbuck.jpg";
import ImgSteenbuck from "../../assets/images/animals/steenbuck.jpg";
import ImgWarthog from "../../assets/images/animals/warthog.jpg";
import ImgWaterbuck from "../../assets/images/animals/waterbuck.jpg";
import ImgWildebeest from "../../assets/images/animals/wildebeest.jpg";
import ImgZebra from "../../assets/images/animals/zebra.jpg";
import ImgGiraffe from "../../assets/images/animals/giraffe.jpg";
import ImgHippo from "../../assets/images/animals/hippo.jpg";
import ImgEland from "../../assets/images/animals/eland.jpg";

/** Animal Info */
import Baboon from "./animalInfo/Baboon";
import Buffalo from "./animalInfo/Buffalo";
import Eland from "./animalInfo/Eland";
import Blesbuck from "./animalInfo/Blesbuck";
import Bushbuck from "./animalInfo/Bushbuck";
import Bushpig from "./animalInfo/Bushpig";
import Civet from "./animalInfo/Civet";
import Giraffe from "./animalInfo/Giraffe";
import Hippo from "./animalInfo/Hippo";
import HoneyBadger from "./animalInfo/HoneyBadger";
import Impala from "./animalInfo/Impala";
import Jackal from "./animalInfo/Jackal";
import Klipspringer from "./animalInfo/Klipspringer";
import Wildebeest from "./animalInfo/Wildebeest";
import Zebra from "./animalInfo/Zebra";
import Waterbuck from "./animalInfo/Waterbuck";
import Warthog from "./animalInfo/Warthog";
import Steenbuck from "./animalInfo/Steenbuck";
import Springbuck from "./animalInfo/Springbuck";
import Sable from "./animalInfo/Sable";
import Roan from "./animalInfo/Roan";
import Reedbuck from "./animalInfo/Reedbuck";
import RedHartebeest from "./animalInfo/RedHartebeest";
import Ostrich from "./animalInfo/Ostrich";
import Oryx from "./animalInfo/Oryx";
import Nyala from "./animalInfo/Nyala";
import Kudu from "./animalInfo/Kudu";

/** Hunting Images */
import HuntBuffalo1 from "../../assets/images/hunting/buffalo/1.jpg";
import HuntBuffalo2 from "../../assets/images/hunting/buffalo/2.jpg";
import HuntBuffalo3 from "../../assets/images/hunting/buffalo/3.jpg";
import HuntBuffalo4 from "../../assets/images/hunting/buffalo/4.jpg";
import HuntBuffalo5 from "../../assets/images/hunting/buffalo/5.jpg";
import HuntBuffalo6 from "../../assets/images/hunting/buffalo/6.jpg";

import HuntBushBuck1 from "../../assets/images/hunting/bushbuck/1.jpg";
import HuntBushBuck2 from "../../assets/images/hunting/bushbuck/2.jpg";

import HuntHippo1 from "../../assets/images/hunting/hippo/1.jpg";
import HuntHippo2 from "../../assets/images/hunting/hippo/2.jpg";

import HuntKudu1 from "../../assets/images/hunting/kudu/1.jpg";
import HuntKudu2 from "../../assets/images/hunting/kudu/2.jpg";
import HuntKudu3 from "../../assets/images/hunting/kudu/3.jpg";

import HuntRedhartebeest1 from "../../assets/images/hunting/redhartebeest/1.jpg";

import HuntWarthog1 from "../../assets/images/hunting/warthog/1.jpg";
import HuntWildebeest1 from "../../assets/images/hunting/wildebeest/1.jpg";
import HuntWildebeest2 from "../../assets/images/hunting/wildebeest/2.jpg";

import HuntImpala1 from "../../assets/images/hunting/impala/1.jpg";
import HuntImpala2 from "../../assets/images/hunting/impala/2.jpg";

import HuntZebra1 from "../../assets/images/hunting/zebra/1.jpg";

import HuntBlesbuck1 from "../../assets/images/hunting/blesbuck/1.jpg";
import HuntWaterbuck1 from "../../assets/images/hunting/waterbuck/1.jpg";

import HuntBushpig1 from "../../assets/images/hunting/bushpig/1.jpg";
import { useMobile } from "../../hooks/use-mobile.hook";

export enum Animal {
  BABOON = "Baboon",
  BLESBUCK = "Blesbuck",
  BUFFALO = "Buffalo",
  BUSHBUCK = "Bushbuck",
  BUSHPIG = "Bushpig",
  CIVET = "Civet",
  DUIKER = "Grey Duiker",
  ELAND = "Eland",
  GIRAFFE = "Giraffe",
  HIPPO = "Hippo",
  HONEY_BADGER = "Honey badger",
  IMPALA = "Impala",
  JACKAL = "Jackal",
  KLIPSPRINGER = "Klipspringer",
  KUDU = "Kudu",
  NYALA = "Nyala",
  ORYX = "Oryx",
  OSTRICH = "Ostrich",
  RED_HARTEBEEST = "Red hartebeest",
  REEDBUCK = "Reedbuck",
  ROAN = "Roan",
  SABLE = "Sable",
  SPRINGBUCK = "Springbuck",
  STEENBUCK = "Steenbuck",
  WARTHOG = "Warthog",
  WATERBUCK = "Waterbuck",
  WILDEBEEST = "Wildebeest",
  ZEBRA = "Zebra",
}

const AnimalsInformation = [
  {
    title: Animal.BABOON,
    component: <Baboon />,
    src: ImgBaboon,
    scientificName: "Papio cynocephalus",
    size: "40 - 86cm",
    weight: "10 - 37kg",
    huntingRating: 1,
  },
  {
    title: Animal.BLESBUCK,
    component: <Blesbuck />,
    src: ImgBlesbuck,
    scientificName: "Damaliscus pygargus phillipsi",
    size: "80 to 95 cm at the shoulder",
    weight: "75 - 130kg",
    variety: "Common Blesbuck, White Blesbuck",
    huntingRating: 1,
    hornSize: "35 - 50cm",
    huntingSrc: [HuntBlesbuck1],
  },
  {
    title: Animal.BUFFALO,
    component: <Buffalo />,
    src: Buffalo2,
    scientificName: "Syncerus caffer",
    size: "1.7m",
    weight: "500 - 900kg",
    variety: "Cape Buffalo",
    huntingSrc: [
      HuntBuffalo1,
      HuntBuffalo2,
      HuntBuffalo3,
      HuntBuffalo4,
      HuntBuffalo5,
      HuntBuffalo6,
    ],
    hornSize: "60 - 150cm",
  },
  {
    title: Animal.BUSHBUCK,
    component: <Bushbuck />,
    src: ImgBushbuck,
    scientificName: "Tragelaphus scriptus",
    size: "90cm",
    weight: "40 - 80kg",
    huntingRating: 1,
    huntingSrc: [HuntBushBuck1, HuntBushBuck2],
    hornSize: "15 - 30cm",
  },
  {
    title: Animal.BUSHPIG,
    component: <Bushpig />,
    src: ImgBushpig,
    scientificName: "Potamochoerus larvatus",
    size: "65-80 cm at the shoulder",
    weight: "40 - 100kg",
    huntingRating: 1,
    huntingSrc: [HuntBushpig1],
    tusks: "15 - 25cm",
  },
  {
    title: Animal.CIVET,
    component: <Civet />,
    src: ImgCivet,
    scientificName: "Civettictis civetta",
    size: "43 - 71cm",
    weight: "2.7 - 6.4kg",
    huntingRating: 1,
    huntingSrc: [],
  },
  {
    title: Animal.DUIKER,
    component: <Buffalo />,
    src: ImgDuiker,
    scientificName: "Sylvicapra grimmia",
    size: "84 - 89cm",
    weight: "9 - 20kg",
    huntingRating: 1,
    huntingSrc: [],
    hornSize: "4 - 6cm",
  },
  {
    title: Animal.ELAND,
    component: <Eland />,
    src: ImgEland,
    scientificName: "Taurotragus oryx",
    size: "1.6m",
    weight: "400 - 1000kg",
    variety: "Cape Eland, Livingstone Eland",
    huntingSrc: [],
    hornSize: "60 - 150cm",
  },
  {
    title: Animal.GIRAFFE,
    component: <Giraffe />,
    src: ImgGiraffe,
    scientificName: "Giraffa camelopardalis",
    size: "5.5m",
    weight: "800 - 1600kg",
    huntingRating: 1,
    huntingSrc: [],
  },
  {
    title: Animal.HIPPO,
    component: <Hippo />,
    src: ImgHippo,
    scientificName: "Hippopotamus amphibius",
    size: "1.5m",
    weight: "1500 - 3200kg",
    huntingRating: 1,
    huntingSrc: [HuntHippo1, HuntHippo2],
    tusks: "50cm",
  },
  {
    title: Animal.HONEY_BADGER,
    component: <HoneyBadger />,
    src: ImgHoneyBadger,
    scientificName: "Mellivora capensis",
    size: "23 - 28cm",
    weight: "9 - 16kg",
    huntingRating: 1,
    huntingSrc: [],
  },
  {
    title: Animal.IMPALA,
    component: <Impala />,
    src: ImgImpala,
    scientificName: "Aepyceros melampus",
    size: "90cm",
    weight: "40 - 80kg",
    variety: "Common Impala, Black-faced Impala",
    huntingSrc: [HuntImpala1, HuntImpala2],
    huntingRating: 1,
    hornSize: "45 - 92cm",
  },
  {
    title: Animal.JACKAL,
    component: <Jackal />,
    src: ImgJackal,
    scientificName: "Canis aureus",
    size: "38 - 48cm",
    weight: "6 - 14kg",
    variety: "Black-backed Jackal, Side-striped Jackal",
    huntingSrc: [],
    huntingRating: 1,
  },
  {
    title: Animal.KLIPSPRINGER,
    component: <Klipspringer />,
    src: ImgKlipspringer,
    scientificName: "Oreotragus oreotragus",
    size: "58 - 64cm",
    weight: "11 - 18kg",
    huntingRating: 1,
    huntingSrc: [],
    hornSize: "7 - 19cm",
  },
  {
    title: Animal.KUDU,
    component: <Kudu />,
    src: ImgKudu,
    scientificName: "Tragelaphus strepsiceros",
    size: "1.6m",
    weight: "190 - 270kg",
    variety: "Greater Kudu, Lesser Kudu",
    huntingSrc: [HuntKudu1, HuntKudu2, HuntKudu3],
    huntingRating: 1,
    hornSize: "120 - 180cm",
  },
  {
    title: Animal.NYALA,
    component: <Nyala />,
    src: ImgNyala,
    scientificName: "Tragelaphus angasii",
    size: "1.1m",
    weight: "55 - 140kg",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "60 - 83cm",
  },
  {
    title: Animal.ORYX,
    component: <Oryx />,
    src: ImgOryx,
    scientificName: "Oryx gazella",
    size: "1.2m",
    weight: "140 - 210kg",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "75 - 100cm",
  },
  {
    title: Animal.OSTRICH,
    component: <Ostrich />,
    src: ImgOstrich,
    scientificName: "Struthio camelus",
    size: "2.1m",
    weight: "63 - 145kg",
    huntingSrc: [],
    huntingRating: 1,
  },
  {
    title: Animal.RED_HARTEBEEST,
    component: <RedHartebeest />,
    src: ImgRedHartebeest,
    scientificName: "Alcelaphus buselaphus",
    size: "1.2m",
    weight: "120 - 180kg",
    huntingSrc: [HuntRedhartebeest1],
    huntingRating: 1,
    hornSize: "60 - 99cm",
  },
  {
    title: Animal.REEDBUCK,
    component: <Reedbuck />,
    src: ImgReedbuck,
    scientificName: "Redunca arundinum",
    size: "1.1m",
    weight: "50 - 90kg",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "25 - 45cm",
  },
  {
    title: Animal.ROAN,
    component: <Roan />,
    src: ImgRoan,
    scientificName: "Hippotragus equinus",
    size: "1.5m",
    weight: "230 - 320kg",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "81 - 165cm",
  },
  {
    title: Animal.SABLE,
    component: <Sable />,
    src: ImgSable,
    scientificName: "Hippotragus niger",
    size: "1.5m",
    weight: "230 - 320kg",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "81 - 165cm",
  },
  {
    title: Animal.SPRINGBUCK,
    component: <Springbuck />,
    src: ImgSpringbuck,
    scientificName: "Antidorcas marsupialis",
    size: "75cm",
    weight: "30 - 50kg",
    variety: "Common Springbuck, Black Springbuck",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "35 - 50cm",
  },
  {
    title: Animal.STEENBUCK,
    component: <Steenbuck />,
    src: ImgSteenbuck,
    scientificName: "Raphicerus campestris",
    size: "60cm",
    weight: "10 - 15kg",
    huntingSrc: [],
    huntingRating: 1,
    hornSize: "7 - 19cm",
  },
  {
    title: Animal.WARTHOG,
    component: <Warthog />,
    src: ImgWarthog,
    scientificName: "Phacochoerus africanus",
    size: "100cm body length",
    weight: "50 - 150kg",
    tusks: "15 - 25cm",
    huntingSrc: [HuntWarthog1],
    huntingRating: 1,
  },
  {
    title: Animal.WATERBUCK,
    component: <Waterbuck />,
    src: ImgWaterbuck,
    scientificName: "Kobus ellipsiprymnus",
    size: "1.3m",
    weight: "160 - 270kg",
    huntingSrc: [HuntWaterbuck1],
    huntingRating: 1,
    hornSize: "60 - 99cm",
  },
  {
    title: Animal.WILDEBEEST,
    component: <Wildebeest />,
    src: ImgWildebeest,
    scientificName: "Connochaetes taurinus",
    size: "1.5m",
    weight: "150 - 250kg",
    variety: "Blue Wildebeest, Black Wildebeest",
    huntingSrc: [HuntWildebeest1, HuntWildebeest2],
    huntingRating: 1,
    hornSize: "60 - 99cm",
  },
  {
    title: Animal.ZEBRA,
    component: <Zebra />,
    src: ImgZebra,
    scientificName: "Equus quagga",
    size: "1.5m",
    weight: "200 - 350kg",
    huntingSrc: [HuntZebra1],
    huntingRating: 1,
  },
];
export default function AnimalListInfo() {
  const [animal, setAnimal] = useState(AnimalsInformation[2]);
  const isMobile = useMobile();

  return (
    <Container>
      <Grid container spacing={2} sx={{ paddingY: 3 }}>
        <Grid item xs={12} lg={4}>
          <List dense sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            maxHeight: isMobile ? "200px" : "800px",
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid #f0f0f0'
            },
            scrollbarColor: '#f0f0f0 #f0f0f0',
            scrollbarWidth: 'thin'
          }}>
            {AnimalsInformation.map((info, index) => (
              <div key={info.title}>
                <ListItem
                  disablePadding
                  onClick={() => setAnimal(info)}
                  sx={{
                    backgroundColor:
                      animal.title === info.title ? "#f0f0f0" : "",
                  }}
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar alt={info.title} src={info.src} />
                    </ListItemAvatar>
                    <ListItemText primary={info.title} />
                  </ListItemButton>
                </ListItem>
                <Divider
                  variant="inset"
                  component="li"
                  sx={{
                    display:
                      animal.title === info.title ||
                        animal.title ===
                        (AnimalsInformation[index + 1]
                          ? AnimalsInformation[index + 1].title
                          : "")
                        ? "none"
                        : "block",
                  }}
                />
              </div>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Container>
            <AnimalInfo info={animal}>{animal.component}</AnimalInfo>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}
